import React from 'react'
import $ from "jquery";
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import GroupsGeneral from "./GroupsGeneral";
import GroupsAvance from "./GroupsAvance";
import GroupsUsers from "./GroupsUsers";
import GroupsPolitiqueSecu from "./GroupsPolitiqueSecu";
import GroupsApplications from "./GroupsApplications";
import GroupsSimcards from "./GroupsSimcards";
import GroupsGeofencing from "./GroupsGeofencing";
import GroupsPermissions from "./GroupsPermissions";
import GroupsWifinetwork from "./GroupsWifinetwork";
import GroupsWallpaper from "./GroupsWallpaper";

import {varGroupDetail} from '../variables';

const ApiGroupdDetail = varGroupDetail.api;
const ApiPolicies = varGroupDetail.apiPolitiquesSecu;
const ApiPoliciesModif = varGroupDetail.apiPolitiquesModification;
const ApiGroupReferenceApplications = varGroupDetail.apiGroupReferenceApplications;
const ApiReferenceApplications = varGroupDetail.apiReferenceApplications;
const ApiGroupUnwantedApplications = varGroupDetail.apiGroupUnwantedApplications;
const ApiGroupWallpaperAndLockscreen = varGroupDetail.apiGroupWallpaper;
const ApiGroupPermissions = varGroupDetail.apiGroupPermissions;
const ApiGroupWifinetwork = varGroupDetail.ApiGroupWifinetwork;
const ApiApplicationList = varGroupDetail.apiApplicationList;
const ApiGroupReferenceApplicationsTestStore = varGroupDetail.apiGroupReferenceApplicationsTestStore;
const ApiGroupUnwantedApplicationsTestStore = varGroupDetail.apiGroupUnwantedApplicationsTestStore;
const ApiGroupSimcard = varGroupDetail.apiGroupSimcard;
const ApiGroupAllApplications = varGroupDetail.apiGroupAllApplications;
const ApiGroupGeofencingZone = varGroupDetail.apiGroupGeofencingZone;
const ApiGroupGeofencingZoneAllApplications = varGroupDetail.apiGroupGeofencingZoneAllApplications;

class GroupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: "",
      validButton: "info",
      administration: 0,
      selectedOption: null,
      selectedReferenceApplication:"",
      selectedOptionExtensions: null,
      oldSelectedOptionExtensions: null,
      props: this.props.match.params.groupName,
      useTestStore: false,
      newGeofencingZoneName: "",
      newGeofencingZoneDescription: "",
      newGeofencingZoneLatitude: 0,
      newGeofencingZoneLatitudeDecimal: 0,
      newGeofencingZoneLongitude: 0,
      newGeofencingZoneLongitudeDecimal: 0,
      newGeofencingZoneRadius: 0,
      newGeofencingZoneColor: 'rgb(255, 0, 0)'
        };

    this.HandleChange = this.HandleChange.bind(this);
    this.handlePoliciesChange = this.handlePoliciesChange.bind(this);
    this.handleDefaultPolicies = this.handleDefaultPolicies.bind(this);
    this.handleCheckboxConfirm = this.handleCheckboxConfirm.bind(this);
    this.handleChangeExtansionsAutorisation = this.handleChangeExtansionsAutorisation.bind(this);
    this.handlePoliciesModif = this.handlePoliciesModif.bind(this);
    this.handleLimitSize = this.handleLimitSize.bind(this);
    this.handleChangeEmailfilter = this.handleChangeEmailfilter.bind(this);

  }


// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

  componentWillMount() {
    this.fetchAll();
  }

  fetchAll(){
    this.setState({isLoading: true});

        Promise.all([
            fetch(ApiGroupdDetail + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}),
            fetch(ApiPolicies + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }})
        ])
        .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
        .then(([data1, data2]) => this.setState({
            groupName: data1.name,
            array: data1,
            useTestStore: data1.mmapp_test,
            arrayPolicies: data2,
            limitSize: data2.maxattachmentsize == "-1" ? false : true,
            defaultPolicies: data2._default ? data2._default : false,
            defaultPoliciesAtFetching: data2._default ? data2._default : false,
            extansionsAutorisation: data2.extensions === "*" ? true : false,
            selectedOptionExtensions: data2.extensions === "*" ? { value: '*', label: '*'} : this.getExtensions(data2.extensions),
            isLoading: false
        }));

        Promise.all([
        fetch(ApiGroupReferenceApplications + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
              this.setState({errorDB_MM_APP_ApiGroupReferenceApplications: err})
              throw {err};
          }),
          fetch(ApiGroupWallpaperAndLockscreen + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                this.setState({errorDB_MM_APP_ApiGroupWallpaperAndLockscreen: err})
                throw {err};
            }),
            fetch(ApiGroupPermissions + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                  this.setState({errorDB_MM_APP_ApiGroupPermissions: err})
                  throw {err};
              }),
              fetch(ApiGroupWifinetwork + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                    this.setState({errorDB_MM_APP_ApiGroupWifinetwork: err})
                    throw {err};
              }),
            fetch(ApiGroupReferenceApplicationsTestStore + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                  this.setState({errorDB_MM_APP_ApiGroupReferenceApplicationsTestStore: err})
                  throw {err};
              }),
            fetch(ApiGroupSimcard + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                  this.setState({errorDB_MM_APP_ApiGroupSimcard: err})
                  throw {err};
              }),
            fetch(ApiGroupAllApplications ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                  this.setState({errorDB_MM_APP_ApiGroupSimcardAllApplications: err})
                  throw {err};
              }),
            fetch(ApiGroupGeofencingZone + this.props.match.params.groupName ,{method: 'GET', headers: { 'X-XSRF-TOKEN': this.props.tokenCSRF }}).catch(err => {
                  this.setState({errorDB_MM_APP_ApiGroupGeofencingZone: err})
                  throw {err};
              })
        ])
        .then(([res1, res2, res3, res4, res5, res6, res7, res8]) => Promise.all([res1.json(), res2.json(), res3.json(), res4.json(), res5.json(), res6.json(), res7.json(), res8.json()]))
        .then(([dataGroupApp, dataGroupWallpaperAndLockscreen, dataGroupPermissions, dataGroupWifinetwork, dataGroupAppTestStore, dataGroupSimcard, dataGroupSimcardAllApplications, dataGroupGeofencingZone]) => this.setState({
            dataGroupReferenceApplications: dataGroupApp.applications,
            dataGroupUnwantedApplications: dataGroupApp.unwantedApplications,
            dataAvailableReferenceApplications: dataGroupApp.applicationsAvailable,
            dataGroupWallpaperAndLockscreen: this.adaptGroupWallpaper(dataGroupWallpaperAndLockscreen),
            dataGroupPermissions,
            dataGroupWifinetwork,
            dataAvailableGroupPermissions: this.checkGroupPermissionsAvailable(dataGroupPermissions),
            dataGroupTestStoreReferenceApplications: dataGroupAppTestStore.applications,
            dataGroupTestStoreUnwantedApplications: dataGroupAppTestStore.unwantedApplications,
            dataGroupTestStoreAvailableReferenceApplications: dataGroupAppTestStore.applicationsAvailable,
            dataGroupSimcard: this.adaptGroupSimcard(dataGroupSimcard),
            dataGroupSimcardAllApplications: dataGroupSimcardAllApplications,
            dataGroupGeofencingZone: this.adaptGroupGeofencingZone(dataGroupGeofencingZone),
            dataGroupGeofencingAllApplications: dataGroupSimcardAllApplications
        }));
  }

  componentDidUpdate() {
    if (this.state.isFetching === true) {
      $('#ModalValideModif').modal('show');
    } else {
      $('#ModalValideModif').modal('hide');
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  adaptDataAppTestStore(data){


    return data;
  }

  adaptGroupSimcard(data){

    var newData = [];

    for (var i = 0; i < data.length; i++) {
      var item = new Object();
      item.simcardName = data[i].simcardName;
      item.operatorCode = data[i].operatorCode;
      item.groupId = data[i].groupId;
      item.autoriseRoaming = data[i].autoriseRoaming;
      if (data[i].applications && data[i].disabledApplications) {
        const listeFusionnee = this.adaptAppDisable(data[i].applications, false).concat(this.adaptAppDisable(data[i].disabledApplications, true));
        item.applications = listeFusionnee;
      } else {
        if (data[i].applications) {
          item.applications = this.adaptAppDisable(data[i].applications, false);
        } else if (data[i].disabledApplications) {
          item.applications = this.adaptAppDisable(data[i].disabledApplications, true);
        }
      }
      item.availableApplications = data[i].availableApplications;

      newData.push(item);
    }

    if (newData.length > 0) {
      this.setState({selectedSimcardInfo: newData[0]})
    }

    return newData;
  }

  adaptGroupGeofencingZone(data){

    var newData = [];

    for (var i = 0; i < data.length; i++) {
      var item = new Object();
      item.name = data[i].name;
      item.description = data[i].description;
      item.coordinates = data[i].coordinates;
      item.radius = data[i].radius;
      item.color = data[i].color;
      if (data[i].applications && data[i].disabledApplications) {
        const listeFusionnee = this.adaptAppDisable(data[i].applications, false).concat(this.adaptAppDisable(data[i].disabledApplications, true));
        item.applications = listeFusionnee;
      } else {
        if (data[i].applications) {
          item.applications = this.adaptAppDisable(data[i].applications, false);
        } else if (data[i].disabledApplications) {
          item.applications = this.adaptAppDisable(data[i].disabledApplications, true);
        }
      }
      item.availableApplications = data[i].availableApplications;

      newData.push(item);
    }

    if (newData.length > 0) {
      this.setState({selectedGeofencingZone: newData[0]})
    }

    return newData;
  }

  adaptAppDisable(listApp, disabled){
    var newListApp = [];
    for (var i = 0; i < listApp.length; i++) {
      var newApp = new Object();
      newApp.applicationSystem = listApp[i].applicationSystem;
      newApp.names = listApp[i].names;
      newApp.packageName = listApp[i].packageName;
      newApp.disabled = disabled;
      if (listApp[i].storeVersionCode) {
        newApp.storeVersion = listApp[i].storeVersion;
        newApp.storeVersionCode = listApp[i].storeVersionCode;
      } else {
        newApp.latestKnownVersion = listApp[i].latestKnownVersion;
        newApp.latestKnownVersionCode = listApp[i].latestKnownVersionCode;
      }
      newListApp.push(newApp);
    }
    return newListApp;
  }


  adaptGroupWallpaper(data){
    var adaptedData = new Object();
    data.map((x) => {
      if (x.lockscreen) {
        var lockscreen = new Object();
        lockscreen.groupId = x.groupId;
        lockscreen.imageLink = x.imageLink;
        lockscreen.lockscreen = x.lockscreen;
        adaptedData.lockscreen = lockscreen;
      } else {
        var wallpaper = new Object();
        wallpaper.groupId = x.groupId;
        wallpaper.imageLink = x.imageLink;
        wallpaper.lockscreen = x.lockscreen;
        adaptedData.wallpaper = wallpaper;
      }
    })

    return adaptedData;
  }

  checkGroupPermissionsAvailable(groupPermissionsUsed){
    if (groupPermissionsUsed[0] != null) {
      var AllPermissions = ["LOCATION", "STORAGE", "CALL_LOG", "CONTACTS", "CALENDAR", "CAMERA", "RECORD_AUDIO", "PHONE", "SMS"];

      for (var i = 0; i < groupPermissionsUsed.length; i++) {
        if (AllPermissions.indexOf(groupPermissionsUsed[i].permission) != -1) {
          AllPermissions.splice(AllPermissions.indexOf(groupPermissionsUsed[i].permission),1)
        }
      }

      return AllPermissions
    } else {
      return (["LOCATION", "STORAGE", "CALL_LOG", "CONTACTS", "CALENDAR", "CAMERA", "RECORD_AUDIO", "PHONE", "SMS"])
    }

  }

  getAvailableReferenceApplications(listAll, listUsed){
    var newTab = [];
    var unUsedTab = [];

    for (var y = 0; y < listAll.length; y++) {
      var finded = false;
      for (var i = 0; i < listUsed.length; i++) {
        if (listUsed[i].id === listAll[y].id) {
          newTab.push(listAll[y]);
          finded = true;
        }
      }
      if (!finded) {
        unUsedTab.push(listAll[y]);
      }
    }
    var selectedDefault = 0;
    if (unUsedTab.length > 0) {
      selectedDefault = unUsedTab[0].id;
    }
    this.setState({selectedReferenceApplication: selectedDefault})
    return(unUsedTab);
  }

  selectedRefApp(e){
    this.setState({selectedReferenceApplication: parseInt(e.target.value)})
  }

  addReferenceApplication() {

    if (this.state.selectedOption) {
      var newUsedList = this.state.dataGroupReferenceApplications;
      var availableList = this.state.dataAvailableReferenceApplications;
      var newAvailableList = [];

      newUsedList.push(this.state.selectedOption.value);

      for (var i = 0; i < availableList.length; i++) {
        if (availableList[i].packageName !== this.state.selectedOption.value.packageName) {
          newAvailableList.push(availableList[i]);
        }
      }

      this.setState({dataGroupReferenceApplications: newUsedList, dataAvailableReferenceApplications: newAvailableList, selectedOption: ""});

    }
  }

  addTestStoreReferenceApplication(){

    if (this.state.selectedOption) {
      var newUsedList = this.state.dataGroupTestStoreReferenceApplications;
      var availableList = this.state.dataGroupTestStoreAvailableReferenceApplications;
      var newAvailableList = [];

      newUsedList.push(this.state.selectedOption);

      for (var i = 0; i < availableList.length; i++) {
        if (availableList[i].application.packageName !== this.state.selectedOption.application.packageName) {
          newAvailableList.push(availableList[i]);
        }
      }

      this.setState({dataGroupTestStoreReferenceApplications: newUsedList, dataGroupTestStoreAvailableReferenceApplications: newAvailableList, selectedOption: ""});

    }
  }

  removeTestStoreReferenceApplication(appli) {

    var usedList = this.state.dataGroupTestStoreReferenceApplications;
    var newAvailableList = this.state.dataGroupTestStoreAvailableReferenceApplications;


    var newUsedList = [];

    newAvailableList.push(appli);

    for (var i = 0; i < usedList.length; i++) {
      if (usedList[i].application.packageName !== appli.application.packageName) {
        newUsedList.push(usedList[i]);
      }
    }

    this.setState({dataGroupTestStoreReferenceApplications: newUsedList, dataGroupTestStoreAvailableReferenceApplications: newAvailableList});

  }


  addUnwantedTestStoreReferenceApplication(){

    if (this.state.selectedOption) {
      var newUsedList = this.state.dataGroupTestStoreUnwantedApplications;
      var availableList = this.state.dataGroupTestStoreAvailableReferenceApplications;
      var newAvailableList = [];

      newUsedList.push(this.state.selectedOption);

      for (var i = 0; i < availableList.length; i++) {
        if (availableList[i].application.packageName !== this.state.selectedOption.application.packageName) {
          newAvailableList.push(availableList[i]);
        }
      }

      this.setState({dataGroupTestStoreUnwantedApplications: newUsedList, dataGroupTestStoreAvailableReferenceApplications: newAvailableList, selectedOption: ""});

    }
  }

  removeUnwantedTestStoreReferenceApplication(appli) {

    var usedList = this.state.dataGroupTestStoreUnwantedApplications;
    var newAvailableList = this.state.dataGroupTestStoreAvailableReferenceApplications;


    var newUsedList = [];

    newAvailableList.push(appli);

    for (var i = 0; i < usedList.length; i++) {
      if (usedList[i].application.packageName !== appli.application.packageName) {
        newUsedList.push(usedList[i]);
      }
    }

    this.setState({dataGroupTestStoreUnwantedApplications: newUsedList, dataGroupTestStoreAvailableReferenceApplications: newAvailableList});

  }

  addGroupWifinetwork() {
    var newWifinetworkName = document.getElementById('WifinetworkNameInput').value
    var newWifinetworkPassword = document.getElementById('WifinetworkPasswordInput').value

    if (newWifinetworkName && newWifinetworkPassword) {
      var newWifinetwork = this.state.dataGroupWifinetwork

      var item = new Object();
      item.groupId = this.state.array.groupId;
      item.wifinetwork = newWifinetworkName;
      item.password = newWifinetworkPassword;

      newWifinetwork.push(item)
      this.setState({dataGroupWifinetwork: newWifinetwork});

      document.getElementById('WifinetworkNameInput').value = "";
      document.getElementById('WifinetworkPasswordInput').value = "";

      $('#modalAddWifinetwork').modal('hide')
    }

  }


  addGroupPermissions() {
    if (this.state.selectedPermission) {
      var newPermissions = this.state.dataGroupPermissions

      var item = new Object();
      item.groupId = this.state.array.groupId;
      item.permission = this.state.selectedPermission.value;

      newPermissions.push(item)
      this.setState({dataGroupPermissions: newPermissions, dataAvailableGroupPermissions: this.checkGroupPermissionsAvailable(newPermissions), selectedPermission: null});
    }
  }

  removeReferenceApplication(appli) {

    var usedList = this.state.dataGroupReferenceApplications;
    var newAvailableList = this.state.dataAvailableReferenceApplications;


    var newUsedList = [];

    newAvailableList.push(appli);

    for (var i = 0; i < usedList.length; i++) {
      if (usedList[i].packageName !== appli.packageName) {
        newUsedList.push(usedList[i]);
      }
    }

    this.setState({dataGroupReferenceApplications: newUsedList, dataAvailableReferenceApplications: newAvailableList});

  }

  removeGroupWifinetwork(index){
    var newWifinetwork = this.state.dataGroupWifinetwork;
    newWifinetwork.splice(index, 1)
    this.setState({dataGroupWifinetwork: newWifinetwork});
  }

  removeGroupPermissions(index) {
    var newPermissions = this.state.dataGroupPermissions;
    newPermissions.splice(index, 1)
    this.setState({dataGroupPermissions: newPermissions, dataAvailableGroupPermissions: this.checkGroupPermissionsAvailable(newPermissions), selectedPermission: null});
  }

  handleChildState = (simcardInfoList) => {
    console.log("État de l'enfant récupéré :", simcardInfoList);
    // Faites quelque chose avec l'état récupéré de la classe enfant
  };

  handleTabChange = (tabId) => {
    this.setState({ activeTab: tabId });
  }

  handleNewGeofencingZoneChange = (newValue, variableName) => {
    const regex = /^[0-9]*$/;

    if (variableName == "newGeofencingZoneLongitude" || variableName == "newGeofencingZoneLongitudeDecimal" || variableName == "newGeofencingZoneLatitude" || variableName == "newGeofencingZoneRadius") {
      if (regex.test(newValue)) {
        this.setState({ [variableName]: parseFloat(newValue) });
      }
    } else {
      this.setState({ [variableName]: newValue });
    }
  }

  handleNewGeofencingZoneColorChange = (color) => {
    this.setState({ newGeofencingZoneColor: color.hex });
  };

  handleGeofencingZoneSelect(event){
    const elementTrouve = this.state.dataGroupGeofencingZone.find((element) => element.name === event.target.value);
    console.log(elementTrouve);
    this.setState({selectedGeofencingZone: elementTrouve})
  }

  showModalNewGeonfencingZone(data){
    const [longitude, ...longitudeDecimal] = data.lng.toFixed(4).split(".");
    const [latitude, ...latitudeDecimal] = data.lat.toFixed(4).split(".");
    this.setState({newGeofencingZoneLongitude: longitude, newGeofencingZoneLongitudeDecimal: longitudeDecimal,
       newGeofencingZoneLatitude: latitude, newGeofencingZoneLatitudeDecimal: latitudeDecimal})
      $('#modalAddGeofencingZone').modal('show');
  }

  addNewGeofencingZone(){
      var newGeofencingZone = this.state.dataGroupGeofencingZone;

      const { newGeofencingZoneName, newGeofencingZoneDescription, newGeofencingZoneLatitude, newGeofencingZoneLatitudeDecimal,
        newGeofencingZoneLongitude, newGeofencingZoneLongitudeDecimal, newGeofencingZoneRadius, newGeofencingZoneColor } = this.state;

      var newElement = {
        name: newGeofencingZoneName,
        coordinates: [newGeofencingZoneLongitude + "." + newGeofencingZoneLongitudeDecimal, newGeofencingZoneLatitude + "." + newGeofencingZoneLatitudeDecimal],
        radius: newGeofencingZoneRadius,
        description: newGeofencingZoneDescription,
        color: newGeofencingZoneColor,
        applications: []
      };

      const elementTrouve = newGeofencingZone.some((element) => element.name.toLowerCase() === newElement.name.toLowerCase());
      if (!elementTrouve) {
        this.setState(prevState => ({
          dataGroupGeofencingZone: [...prevState.dataGroupGeofencingZone, newElement],
          newGeofencingZoneName: "",
          newGeofencingZoneDescription: "",
          newGeofencingZoneRadius: 0,
          newGeofencingZoneColor: 'rgb(255, 0, 0)'
        }))
      }
      if (newGeofencingZone.length == 0) {
        this.setState({selectedGeofencingZone: newElement})
      }
    }

    addReferenceApplicationGeofencingZone(){
        var newTab = this.state.selectedGeofencingZone;
        var selectedApp = this.state.selectedOptionGeofencingZoneApp.value;
        selectedApp.disabled = false;
        newTab.applications.push(selectedApp);
        this.setState({selectedGeofencingZone: newTab, selectedOptionGeofencingZoneApp: null})
    }

  handleGroupWallpaperAndLockscreen(file, type) {
      var newDataGroupWallpaperAndLockscreen = this.state.dataGroupWallpaperAndLockscreen;

      if (type === "wallpaper") {
        var wallpaper = new Object();
        wallpaper.groupId = this.state.array.groupId;
        wallpaper.imageLink = file.name;
        wallpaper.lockscreen = false;
        newDataGroupWallpaperAndLockscreen.wallpaper = wallpaper;
      } else {
        var lockscreen = new Object();
        lockscreen.groupId = this.state.array.groupId;
        lockscreen.imageLink = file.name;
        lockscreen.lockscreen = true;
        newDataGroupWallpaperAndLockscreen.lockscreen = lockscreen;
      }
      console.log(newDataGroupWallpaperAndLockscreen);
      this.setState({dataGroupWallpaperAndLockscreen: newDataGroupWallpaperAndLockscreen});
    }

    deleteGroupWallpaperOrLockscreen(lockscreen){

      var newDataGroupWallpaperAndLockscreen = this.state.dataGroupWallpaperAndLockscreen;
      if (lockscreen) {
        newDataGroupWallpaperAndLockscreen.lockscreen.imageLink = null;
      } else {
        newDataGroupWallpaperAndLockscreen.wallpaper.imageLink = null;
      }

      this.setState({dataGroupWallpaperAndLockscreen: newDataGroupWallpaperAndLockscreen});

    }


  addUnwantedApplication() {

        if (this.state.selectedOption) {
          var newUsedList = this.state.dataGroupUnwantedApplications;
          var availableList = this.state.dataAvailableReferenceApplications;
          var newAvailableList = [];

          newUsedList.push(this.state.selectedOption.value);

          for (var i = 0; i < availableList.length; i++) {
            if (availableList[i].packageName !== this.state.selectedOption.value.packageName) {
              newAvailableList.push(availableList[i]);
            }
          }

          this.setState({dataGroupUnwantedApplications: newUsedList, dataAvailableReferenceApplications: newAvailableList, selectedOption: ""});

        }
  }

  removeUnwantedApplication(appli) {

        var usedList = this.state.dataGroupUnwantedApplications;
        var newAvailableList = this.state.dataAvailableReferenceApplications;


        var newUsedList = [];

        newAvailableList.push(appli);

        for (var i = 0; i < usedList.length; i++) {
          if (usedList[i].packageName !== appli.packageName) {
            newUsedList.push(usedList[i]);
          }
        }

        this.setState({dataGroupUnwantedApplications: newUsedList, dataAvailableReferenceApplications: newAvailableList});

  }


  getExtensions(extensions){

    var newData = [];

    if (extensions.length > 2) {
      var myArray = extensions.substring(1, extensions.length - 1).split(',');

        for (var i = 0; i < myArray.length; i++) {
          var item = new Object();
          item.value = myArray[i].substring(1, myArray[i].length - 1);
          item.label = myArray[i].substring(1, myArray[i].length - 1);

          newData.push(item);
        }
    }

    return(newData)
  }


// ------------------------------- Action handle ------------------------------- //

  static backPage() {
    window.history.back();
  }

  handleGroupPermissions = (event) => {
    this.setState({groupPermissions: event.target.value});
  }

  handlePermissionSelect = (event) => {
    this.setState({selectedPermission: event});
  }

  handleChangeSelect = selectedOption => {
    this.setState({selectedOptionExtensions : selectedOption})
  }

  handleChangeExtansionsAutorisation = state => {
    if (!this.state.extansionsAutorisation) {
      this.setState({
        arrayPolicies: {
          ...this.state.arrayPolicies,
          ["extensions"]: "*"
        },
        oldSelectedOptionExtensions: this.state.selectedOptionExtensions,
        selectedOptionExtensions: { value: '*', label: '*'}
      });
    } else {
      this.setState({
        arrayPolicies: {
          ...this.state.arrayPolicies,
          ["extensions"]: {}
        },
        selectedOptionExtensions: this.state.oldSelectedOptionExtensions
      });
    }
    this.setState({extansionsAutorisation : !this.state.extansionsAutorisation});
  }

  handleChangeEmailfilter = selectedOption => {
    this.setState({
      arrayPolicies: {
        ...this.state.arrayPolicies,
        ["emailfilter"]: selectedOption.target.value
      }
    })
  }


  handleChangeSelectApp = selectedOption => {
    this.setState({ selectedOption });
  }


    handleGroupTestStoreApplicationVersionSelect(event, appli, index, unwanted){
      if (unwanted) {
        var referenceApp = this.state.dataGroupTestStoreUnwantedApplications;

        referenceApp[index].application.storeVersion = event.target.options[event.target.selectedIndex].text;
        referenceApp[index].application.storeVersionCode = event.target.value;

        this.setState({ dataGroupTestStoreUnwantedApplications: referenceApp });
      } else {
        var referenceApp = this.state.dataGroupTestStoreReferenceApplications;

        referenceApp[index].application.storeVersion = event.target.options[event.target.selectedIndex].text;
        referenceApp[index].application.storeVersionCode = event.target.value;

        this.setState({ dataGroupTestStoreReferenceApplications: referenceApp });
      }

    }

  handleUseTestStoreChange(){

        var data = {
          "name": this.state.array.name,
          "administration": this.state.array.administration,
          "mmapp_test": !this.state.array.mmapp_test,
        };

        fetch(ApiGroupdDetail + this.state.array.groupId, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: JSON.stringify(data)
        }).then(response => {
          console.log(response);
          var x = response;
          if(response.status === 200){
            this.fetchAll();
          }
          else{
            this.setState({sendingModif: false, validButton: "danger"})
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })

  }

  HandleChange = (event, field) => {
    this.setState({
      array: {
        ...this.state.array,
        [field]: event.target.value
      }
    });
  }

  handlePoliciesChange(e, field){
    this.setState({
      modalCheckboxTitle : "Confirmation de modification",
      modalCheckboxButton : !this.state.arrayPolicies[field] ? this.props.intl.formatMessage({id: "activer"}) : this.props.intl.formatMessage({id: "désactiver"}),
      modalCheckboxMsg : this.warningMessage(field, !this.state.arrayPolicies[field]),
      modalCheckboxField : [field]
    });
    $('#modalCheckbox').modal('show')
  }

  warningMessage(field, state){

    if(state === false){

      switch(field) {
        case "wifi":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.1.disable"}))
        case "browser":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.2.disable"}))
        case "bluetooth":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.3.disable"}))
        case "camera":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.4.disable"}))
        case "storagecard":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.5.disable"}))
        case "unsignedapplications":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.6.disable"}))
        case "desktopsync":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.7.disable"}))
        case "textmessaging":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.8.disable"}))
        case "requiremanualsyncwhenroaming":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.9.disable"}))
        case "onlyheader":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.10.disable"}))
        case "requiredevicencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.11.disable"}))
        case "requirestoragecardencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.12.disable"}))
        case "password":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.13.disable"}))
        default:
          return("default")
      }

    }else {
      switch(field) {
        case "wifi":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.1.enable"}))
        case "browser":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.2.enable"}))
        case "bluetooth":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.3.enable"}))
        case "camera":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.4.enable"}))
        case "storagecard":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.5.enable"}))
        case "unsignedapplications":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.6.enable"}))
        case "desktopsync":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.7.enable"}))
        case "textmessaging":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.8.enable"}))
        case "requiremanualsyncwhenroaming":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.9.enable"}))
        case "onlyheader":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.10.enable"}))
        case "requiredevicencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.11.enable"}))
        case "requirestoragecardencryption":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.12.enable"}))
        case "password":
            return(this.props.intl.formatMessage({id: "userDetails.MMI.politiquesSecu.modal.13.enable"}))
        default:
          return("default")
      }
    }
  }

  handleCheckboxConfirm(field){

    if(String(field) === "password" && this.state.arrayPolicies.requiredevicencryption === true){
      return
    }

      if(String(field) === "requiredevicencryption" && !this.state.arrayPolicies[field] === true){
        var x = this.state.arrayPolicies;
        x.password = true;
        this.setState({ arrayPolicies: x });
      }

    this.setState({
      arrayPolicies: { ...this.state.arrayPolicies, [field]: !this.state.arrayPolicies[field] }
    });
  }


  handleDefaultPolicies() {
    this.setState({defaultPolicies: !this.state.defaultPolicies});
  }

  handleSelectChange(event){
    const field = "administration";
    this.setState({
      array: {
        ...this.state.array,
        [field]: event.value
      }
    });
  }

  handlePoliciesModif(event, field){
    this.setState({
      arrayPolicies: {
        ...this.state.arrayPolicies,
        [field]: event.target.value
      }
    });
  }

  handleLimitSize(event){
    this.setState({limitSize: !this.state.limitSize});
  }

  deleteGroup() {

    this.setState({isFetching: true});

    fetch(ApiGroupdDetail + this.state.array.groupId, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
      body: ""
    }).then(response => {
      console.log(response);
      var x = response;
      if (response.status === 200) {
        this.props.history.push("/Groups/");
      } else {
        return response.json().then(responseError => {
          console.log(responseError);
          this.requeteResponse(x, responseError);
        })
      }
    })

    this.setState({isFetching: false});
  }

  setGroupTestStore() {

    this.setState({sendingModif: true, validButton: "info"});

    var data = {
      "name": this.state.array.name,
      "administration": this.state.array.administration,
      "mmapp_test": !this.state.array.mmapp_test,
    };

    fetch(ApiGroupdDetail + this.state.array.groupId, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': this.props.tokenCSRF
      },
      body: JSON.stringify(data)
    }).then(response => {
      console.log(response);
      var x = response;
      if(response.status === 200){
        this.props.history.push("/Groups/");
      }
      else{
        this.setState({sendingModif: false, validButton: "danger"})
        return response.json().then(responseError => {
          console.log(responseError);
          this.requeteResponse(x, responseError);
        })
      }
    })

  }


    sendModif() {

      this.modifGroupGeneral();


      if (this.state.useTestStore ){
        if (this.state.dataGroupTestStoreReferenceApplications) {
          this.modifTestStoreReferenceApplication();
        }
      } else {
        if (this.state.dataGroupReferenceApplications != null) {
          this.modifReferenceApplication();
        }
      }

      if (this.state.dataGroupWallpaperAndLockscreen != null) {
          this.modifGroupWallpaperAndLockscreen();
      }

      if (this.state.dataGroupPermissions != null) {
          this.modifGroupPermissions();
      }

      if (this.state.dataGroupWifinetwork != null) {
          this.modifGroupWifinetwork();
      }

      if (this.state.dataGroupSimcard != null) {
          this.modifGroupSimcard();
      }

      if (this.state.dataGroupGeofencingZone != null) {
          this.modifGroupGeofencingZone();
      }


        if(!this.state.defaultPoliciesAtFetching && this.state.defaultPolicies){
          this.deletePolicies();
        }else if(this.state.defaultPoliciesAtFetching && this.state.defaultPolicies){

        } else {
          this.modifPolicies();
        }

    }


    modifPolicies(){

      var finalExtensions = null;
      if (this.state.extansionsAutorisation) {
        finalExtensions = "*";
      } else if (this.state.selectedOptionExtensions && this.state.selectedOptionExtensions.length > 0){
        var adaptExtensions = [];
        this.state.selectedOptionExtensions.map((x) => {
          adaptExtensions.push(x.label)
        })
        finalExtensions = JSON.stringify(adaptExtensions);
      }

      var maxattachmentsize = -1;


      this.setState({sendingModif: true, validButton: "info"});
                  var adaptData = this.state.arrayPolicies;

                  var data = {
                        "bluetooth": adaptData.bluetooth,
                        "browser": adaptData.browser,
                        "camera": adaptData.camera,
                        "emailfilter": adaptData.emailfilter,
                        "password": adaptData.password,
                        "storagecard": adaptData.storagecard,
                        "textmessaging": adaptData.textmessaging,
                        "wifi": adaptData.wifi,
                        "maxattachmentsize": !this.state.limitSize ? "-1" : adaptData.maxattachmentsize,
                        "maxdevicepasswordfailedattempts": !this.state.arrayPolicies.password ? "-1" : adaptData.maxdevicepasswordfailedattempts,
                        "requiremanualsyncwhenroaming": adaptData.requiremanualsyncwhenroaming,
                        "extensions": finalExtensions,
                        "unsignedapplications": adaptData.unsignedapplications,
                        "desktopsync": adaptData.desktopsync,
                        "onlyheader": adaptData.onlyheader,
                        "requiredevicencryption": adaptData.requiredevicencryption,
                        "requirestoragecardencryption": adaptData.requirestoragecardencryption
                      };

                      if(!adaptData._default){

                        fetch(ApiPoliciesModif + adaptData.policySecurityId, {
                          method: 'PUT',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-XSRF-TOKEN': this.props.tokenCSRF
                          },
                          body: JSON.stringify(data)
                        }).then(response => {
                          var x = response;
                          if (response.status === 200) {
                            console.log(response);
                            this.setState({sendingModif: false, validButton: "success"})}
                          else{
                            this.setState({sendingModif: false, validButton: "danger"})
                            return response.json().then(responseError => {
                              console.log(responseError);
                              this.requeteResponse(x, responseError);
                            })
                          }
                        })

                      } else {
                        fetch(ApiGroupdDetail + this.state.array.groupId + "/policy", {
                            method: 'POST',
                            headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-XSRF-TOKEN': this.props.tokenCSRF
                          },
                            body: JSON.stringify(data)
                          })
                          .then(response => {
                            var x = response;
                            if(response.status === 200){
                              console.log(response);
                              this.setState({sendingModif: false, validButton: "success"})}
                            else{
                              this.setState({sendingModif: false, validButton: "danger"})
                              console.log(response.headers);
                             return response.json().then(responseError => {
                               console.log(responseError);
                               this.requeteResponse(x,responseError);
                             })
                           }
                           })
                      }

    }


    deletePolicies(){
      fetch(ApiGroupdDetail + this.state.array.groupId + "/policy", {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-XSRF-TOKEN': this.props.tokenCSRF
        },
        body: ""
      }).then(response => {
        console.log(response);
        var x = response;
        if (response.status === 200) {
        } else {
          return response.json().then(responseError => {
            console.log(responseError);
            this.requeteResponse(x, responseError);
          })
        }
      })
    }



    modifGroupGeneral(){

            this.setState({sendingModif: true, validButton: "info"});

            var data = {
              "name": this.state.array.name,
              "administration": this.state.array.administration,
              "iseditable": this.state.array.iseditable,
              "isremovable": this.state.array.isremovable
            };

            fetch(ApiGroupdDetail + this.state.array.groupId, {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
              body: JSON.stringify(data)
            }).then(response => {
              console.log(response);
              var x = response;
              if(response.status === 200){this.setState({sendingModif: false, validButton: "success"})}
              else{
                this.setState({sendingModif: false, validButton: "danger"})
                return response.json().then(responseError => {
                  console.log(responseError);
                  this.requeteResponse(x, responseError);
                })
              }
            })

    }

    modifTestStoreReferenceApplication(){

                  this.setState({sendingModif: true, validButton: "info"});

                  var adaptRefApp = this.state.dataGroupTestStoreReferenceApplications;
                  var data = [];

                  for (var i = 0; i < adaptRefApp.length; i++) {
                    var item = new Object();
                    item.packageName = adaptRefApp[i].application.packageName;
                    item.storeVersion = adaptRefApp[i].application.storeVersion;
                    item.storeVersionCode = adaptRefApp[i].application.storeVersionCode;

                    data.push(item);
                  }

                  fetch(ApiGroupReferenceApplicationsTestStore + this.state.array.groupId , {
                    method: 'PUT',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                  }).then(response => {
                    console.log(response);
                    var x = response;
                    if(response.status === 200){
                      this.setState({sendingModif: false, validButton: "success"})
                      this.modifTestStoreUnwantedApplication()
                    }
                    else{
                      this.setState({sendingModif: false, validButton: "danger"})
                      return response.json().then(responseError => {
                        console.log(responseError);
                        this.requeteResponse(x, responseError);
                      })
                    }
                  })
    }


    modifTestStoreUnwantedApplication(){

            this.setState({sendingModif: true, validButton: "info"});

            var adaptRefApp = this.state.dataGroupTestStoreUnwantedApplications;
            var data = [];

            for (var i = 0; i < adaptRefApp.length; i++) {
              var item = new Object();
              item.packageName = adaptRefApp[i].application.packageName;
              item.storeVersion = adaptRefApp[i].application.storeVersion;
              item.storeVersionCode = adaptRefApp[i].application.storeVersionCode;

              data.push(item);
            }

            fetch(ApiGroupUnwantedApplicationsTestStore + this.state.array.groupId , {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
              body: JSON.stringify(data)
            }).then(response => {
              console.log(response);
              var x = response;
              if(response.status === 200){this.setState({sendingModif: false, validButton: "success"})}
              else{
                this.setState({sendingModif: false, validButton: "danger"})
                return response.json().then(responseError => {
                  console.log(responseError);
                  this.requeteResponse(x, responseError);
                })
              }
            })

    }


    modifReferenceApplication(){

            this.setState({sendingModif: true, validButton: "info"});

            var adaptRefApp = this.state.dataGroupReferenceApplications;
            var data = [];

            for (var i = 0; i < adaptRefApp.length; i++) {
              data.push(adaptRefApp[i].packageName);
            }

            fetch(ApiGroupReferenceApplications + this.state.array.groupId , {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
              body: JSON.stringify(data)
            }).then(response => {
              console.log(response);
              var x = response;
              if(response.status === 200){
                this.setState({sendingModif: false, validButton: "success"})
                this.modifUnwantedApplication()
              }
              else{
                this.setState({sendingModif: false, validButton: "danger"})
                return response.json().then(responseError => {
                  console.log(responseError);
                  this.requeteResponse(x, responseError);
                })
              }
            })

    }

    modifGroupWallpaperAndLockscreen(){

      if (this.state.dataGroupWallpaperAndLockscreen.wallpaper != null) {
        this.setState({sendingModif: true, validButton: "info"});

        fetch(ApiGroupWallpaperAndLockscreen + this.state.array.groupId , {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: JSON.stringify(this.state.dataGroupWallpaperAndLockscreen.wallpaper)
        }).then(response => {
          console.log(response);
          var x = response;
          if(response.status === 200){
            this.setState({sendingModif: false, validButton: "success"})
          }
          else{
            this.setState({sendingModif: false, validButton: "danger"})
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })
      }

      if (this.state.dataGroupWallpaperAndLockscreen.lockscreen != null) {
        this.setState({sendingModif: true, validButton: "info"});

          fetch(ApiGroupWallpaperAndLockscreen + this.state.array.groupId , {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
            body: JSON.stringify(this.state.dataGroupWallpaperAndLockscreen.lockscreen)
          }).then(response => {
            console.log(response);
            var x = response;
            if(response.status === 200){
              this.setState({sendingModif: false, validButton: "success"})
            }
            else{
              this.setState({sendingModif: false, validButton: "danger"})
              return response.json().then(responseError => {
                console.log(responseError);
                this.requeteResponse(x, responseError);
              })
            }
          })
      }


    }

    modifGroupPermissions(){
        this.setState({sendingModif: true, validButton: "info"});

        fetch(ApiGroupPermissions + this.state.array.groupId , {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: JSON.stringify(this.state.dataGroupPermissions)
        }).then(response => {
          console.log(response);
          var x = response;
          if(response.status === 200){
            this.setState({sendingModif: false, validButton: "success"})
          }
          else{
            this.setState({sendingModif: false, validButton: "danger"})
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })
    }

    modifGroupWifinetwork(){
        this.setState({sendingModif: true, validButton: "info"});

        fetch(ApiGroupWifinetwork + this.state.array.groupId , {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: JSON.stringify(this.state.dataGroupWifinetwork)
        }).then(response => {
          console.log(response);
          var x = response;
          if(response.status === 200){
            this.setState({sendingModif: false, validButton: "success"})
          }
          else{
            this.setState({sendingModif: false, validButton: "danger"})
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })
    }


    addNewSimcard(){
      var newTab = this.state.dataGroupSimcard;
      var newSimcardName = document.getElementById('SimcardNameInput').value
      var newSimcardOperatorCode = document.getElementById('SimcardOperatorCodeInput').value

      const elementTrouve = newTab.some((element) => element.simcardName === newSimcardName || element.operatorCode === newSimcardOperatorCode );
      if (!elementTrouve) {
        var newElement = new Object;
        newElement.simcardName = newSimcardName;
        newElement.operatorCode = newSimcardOperatorCode;
        newElement.autoriseRoaming = true;
        newElement.applications = [];

        newTab.push(newElement);

      }
      document.getElementById('SimcardNameInput').value = "";
      document.getElementById('SimcardOperatorCodeInput').value = "";

      for (var i = 0; i < newTab.length; i++) {
        if (newTab[i].simcardName == newSimcardName) {
          this.setState({dataGroupSimcard: newTab, selectedSimcardInfo: newTab[i]})
        }
      }

      $('#modalAddNewSimcard').modal('hide')

    }

    addReferenceApplicationSimcard(){
      var newTab = this.state.selectedSimcardInfo;
      newTab.applications.push(this.state.selectedOptionSimcardApp.value);
      this.setState({selectedSimcardInfo: newTab, selectedOptionSimcardApp: null})
    }

    handleSimcardInfoSelect(event){
      const elementTrouve = this.state.dataGroupSimcard.find((element) => element.simcardName === event.target.value);
      this.setState({selectedSimcardInfo: elementTrouve})
    }

    handleChangeSelectAppSimcard = selectedOptionSimcardApp => {
      this.setState({ selectedOptionSimcardApp });
    }

    handleChangeSelectAppGeofencingZone = selectedOptionGeofencingZoneApp => {
      this.setState({ selectedOptionGeofencingZoneApp });
    }

    handleAurotiseRoamingChange(){
      const elementTrouve = this.state.dataGroupSimcard.find((simcard) => simcard === this.state.selectedSimcardInfo);
      elementTrouve.autoriseRoaming = !elementTrouve.autoriseRoaming;

      const nouvelleListe = this.state.dataGroupSimcard.map((objet) => {
        if (objet.simcardName === elementTrouve.simcardName && objet.operatorCode === elementTrouve.operatorCode) {
          return elementTrouve;
        }
        return objet;
      });

      this.setState({dataGroupSimcard: nouvelleListe, selectedSimcardInfo: elementTrouve})
    }

    handleSimcardApplicationDisabled(selectedAppli){
      var newSimcard = this.state.selectedSimcardInfo;
      const elementTrouve = this.state.selectedSimcardInfo.applications.find((app) => app.packageName === selectedAppli.packageName);
      elementTrouve.disabled = !elementTrouve.disabled ;

      const nouvelleListe = newSimcard.applications.map((objet) => {
        if (objet.packageName === elementTrouve.packageName) {
          return elementTrouve;
        }
        return objet;
      });
      newSimcard.applications = nouvelleListe;

      this.setState({selectedSimcardInfo: newSimcard})
    }

    handleGeofencingZoneApplicationDisabled(selectedAppli){
      var newGeofencingZone = this.state.selectedGeofencingZone;
      const elementTrouve = this.state.selectedGeofencingZone.applications.find((app) => app.packageName === selectedAppli.packageName);
      elementTrouve.disabled = !elementTrouve.disabled;
      console.log("selected zone : " + newGeofencingZone.name);
      console.log("selected appli : " + selectedAppli.packageName);

      console.log("result : " + elementTrouve.packageName + " // disabled : " + elementTrouve.disabled);

      const nouvelleListe = newGeofencingZone.applications.map((objet) => {
        if (objet.packageName === elementTrouve.packageName) {
          return elementTrouve;
        }
        return objet;
      });
      newGeofencingZone.applications = nouvelleListe;

      console.log(" new list ");
      console.log(newGeofencingZone);
      this.setState({selectedGeofencingZone: newGeofencingZone})
    }

    removeSimcardApplication(appli) {
      var listeSelectedSimcard = this.state.selectedSimcardInfo;
      const newTab = listeSelectedSimcard.applications.filter((objet) => objet.packageName !== appli.packageName);
      listeSelectedSimcard.applications = newTab;
      this.setState({selectedSimcardInfo: listeSelectedSimcard, selectedOptionSimcardApp: null})
    }

    removeGeofencingZoneApplication(appli) {
      var listeSelectedGeofencingZone = this.state.selectedGeofencingZone;
      const newTab = listeSelectedGeofencingZone.applications.filter((objet) => objet.packageName !== appli.packageName);
      listeSelectedGeofencingZone.applications = newTab;
      this.setState({selectedGeofencingZone: listeSelectedGeofencingZone, selectedOptionGeofencingZoneApp: null})
    }

    removeGeofencingZone(zoneId) {
        var dataGroupGeofencingZone = this.state.dataGroupGeofencingZone;
        var selectedGeofencingZone = this.state.selectedGeofencingZone;
        const newTab = dataGroupGeofencingZone.filter((objet) => objet.name !== zoneId);
        dataGroupGeofencingZone = newTab;
        if (this.state.selectedGeofencingZone.name == zoneId) {
          selectedGeofencingZone = dataGroupGeofencingZone[0];
        }
        this.setState({dataGroupGeofencingZone: dataGroupGeofencingZone, selectedGeofencingZone})
    }


    modifGroupSimcard(){
        this.setState({sendingModif: true, validButton: "info"});

        var appListToSend = [];
        var simcardList = this.state.dataGroupSimcard;

        console.log(simcardList);
        console.log(this.state.selectedSimcardInfo);

        for (var i = 0; i < simcardList.length; i++) {
          for (var y = 0; y < simcardList[i].applications.length; y++) {

            var app = new Object();
            app.application = simcardList[i].applications[y];
            if (simcardList[i].applications[y].disabled) {
              app.disabled = simcardList[i].applications[y].disabled;
            } else {
              app.disabled = false;
            }

            app.autoriseRoaming = simcardList[i].autoriseRoaming;
            app.groupId = this.state.array.groupId;
            app.operatorCode = simcardList[i].operatorCode;
            app.simcardName = simcardList[i].simcardName;

            appListToSend.push(app);

          }
        }

        console.log(appListToSend);


        fetch(ApiGroupSimcard + this.state.array.groupId , {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.props.tokenCSRF
          },
          body: JSON.stringify(appListToSend)
        }).then(response => {
          console.log(response);
          var x = response;
          if(response.status === 200){
            this.setState({sendingModif: false, validButton: "success"})
          }
          else{
            this.setState({sendingModif: false, validButton: "danger"})
            return response.json().then(responseError => {
              console.log(responseError);
              this.requeteResponse(x, responseError);
            })
          }
        })

    }

    modifGroupGeofencingZone(){

          this.setState({sendingModif: true, validButton: "info"});

          var dataGroupGeofencingZoneListToSend = [];
          const dataGroupGeofencingZoneList = this.state.dataGroupGeofencingZone;



          for (var i = 0; i < dataGroupGeofencingZoneList.length; i++) {
            for (var y = 0; y < dataGroupGeofencingZoneList[i].applications.length; y++) {

              var geofencingZone = new Object();
              geofencingZone.application = dataGroupGeofencingZoneList[i].applications[y];
              if (dataGroupGeofencingZoneList[i].applications[y].disabled) {
                geofencingZone.disabled = dataGroupGeofencingZoneList[i].applications[y].disabled;
              } else {
                geofencingZone.disabled = false;
              }

              geofencingZone.groupId = this.state.array.groupId;
              geofencingZone.name = dataGroupGeofencingZoneList[i].name;
              geofencingZone.description = dataGroupGeofencingZoneList[i].description;
              geofencingZone.radius = dataGroupGeofencingZoneList[i].radius;
              geofencingZone.coordinates = dataGroupGeofencingZoneList[i].coordinates;
              geofencingZone.color = dataGroupGeofencingZoneList[i].color;

              dataGroupGeofencingZoneListToSend.push(geofencingZone);

            }
          }

          console.log(dataGroupGeofencingZoneListToSend);


          fetch(ApiGroupGeofencingZone + this.state.array.groupId , {
            method: 'PUT',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-XSRF-TOKEN': this.props.tokenCSRF
            },
            body: JSON.stringify(dataGroupGeofencingZoneListToSend)
          }).then(response => {
            console.log(response);
            var x = response;
            if(response.status === 200){
              this.setState({sendingModif: false, validButton: "success"})
            }
            else{
              this.setState({sendingModif: false, validButton: "danger"})
              return response.json().then(responseError => {
                console.log(responseError);
                this.requeteResponse(x, responseError);
              })
            }
          })


    }


    modifUnwantedApplication(){

            this.setState({sendingModif: true, validButton: "info"});

            var adaptRefApp = this.state.dataGroupUnwantedApplications;
            var data = [];

            for (var i = 0; i < adaptRefApp.length; i++) {
              data.push(adaptRefApp[i].packageName);
            }

            fetch(ApiGroupUnwantedApplications + this.state.array.groupId , {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': this.props.tokenCSRF
              },
              body: JSON.stringify(data)
            }).then(response => {
              console.log(response);
              var x = response;
              if(response.status === 200){this.setState({sendingModif: false, validButton: "success"})}
              else{
                this.setState({sendingModif: false, validButton: "danger"})
                return response.json().then(responseError => {
                  console.log(responseError);
                  this.requeteResponse(x, responseError);
                })
              }
            })

    }


      requeteResponse(response, responseError) {
        this.setState({codeStatus: responseError.status, errorM: responseError.error, errorMsg: responseError.message});
        switch (responseError.status) {
          default:
            $('#ModalErreur').modal('show');
            break;
        }
      }


// ------------------------------- DATA fetching ------------------------------- //



// ------------------------------- Render -------------------------------------- //

  render() {
    const {isLoading, error} = this.state;

    if (error) {
      return (<div>
        <div className="alert alert-info alert-dismissible fade show mt-3 text-center" role="alert">

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span>Error : {error.message}</span>
        </div>

        <div className="container small"></div>
      </div>);
    }

    if (isLoading) {

      return (<div className="card mt-5 small">
        <div className="card-header text-info">
          <b>
            {this.props.intl.formatMessage({id: "chargement"})} ...
          </b>
        </div>
        <div className="card-body d-flex justify-content-center">

          <div className="spinner-border text-info" role="status">
            <span className="sr-only"> {this.props.intl.formatMessage({id: "chargement"})} ...</span>
          </div>
        </div>
      </div>);
    }

    return (<div className="small col-6 mt-4 mb-5" style={{ minWidth: "65vw"}}>

      <h5 className="text-primary text-center mt-2">
        <b><FormattedMessage id="groupsDetails.1"/>
          <span className="text-success">
            <i>{this.state.groupName}</i>
          </span>
        </b>
        {
          this.state.array.isremovable && this.props.userAdministration === "ADMINISTRATOR"
            ? (<button className="btn btn-danger float-right" data-toggle="modal" data-target="#ConfirmationSupression">X</button>)
            : (<div/>)
        }
      </h5>

      <ul className="nav nav-tabs mt-3 justify-content-center" id="groupsDetails" role="tablist">
        <li className="nav-item" onClick={() => this.handleTabChange('Général')}>
          <a className="nav-link active"
          data-toggle="tab"
          href="#Général"
          role="tab">
            <FormattedMessage id="groupsDetails.2" />
          </a>
        </li>

        <li className="nav-item" onClick={() => this.handleTabChange('Utilisateurs')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Utilisateurs"
          role="tab">
            <FormattedMessage id="groupsDetails.4" />
          </a>
        </li>
        <li className="nav-item" onClick={() => this.handleTabChange('Politiques')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Politiques"
          role="tab">
            <FormattedMessage id="groupsDetails.5"/>
          </a>
        </li>


        <li className="nav-item" onClick={() => this.handleTabChange('Applications')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Applications"
          role="tab">
            <FormattedMessage id="groupsDetails.6"/>
          </a>
        </li>

        <li className="nav-item" onClick={() => this.handleTabChange('Réseaux')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Réseaux"
          role="tab">
            <FormattedMessage id="groupsDetails.wifi"/>
          </a>
        </li>

        <li className="nav-item" onClick={() => this.handleTabChange('Simcards')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Simcards"
          role="tab">
            <FormattedMessage id="groupsDetails.simcards"/>
          </a>
        </li>

        <li className="nav-item" onClick={() => this.handleTabChange('Geofencing')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Geofencing"
          role="tab">
            <FormattedMessage id="groupsDetails.geofencing"/>
          </a>
        </li>

        <li className="nav-item" onClick={() => this.handleTabChange('Permissions')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#Permissions"
          role="tab">
            <FormattedMessage id="groupsDetails.7"/>
          </a>
        </li>

        <li className="nav-item" onClick={() => this.handleTabChange('WallpaperAndLockscreen')}>
          <a className="nav-link "
          data-toggle="tab"
          href="#WallpaperAndLockscreen"
          role="tab">
            <FormattedMessage id="groupsDetails.8"/>
          </a>
        </li>


      </ul>
      <div className="tab-content card border-0" id="myTabContent">
        <div className="tab-pane show active " id="Général" role="tabpanel" aria-labelledby="home-tab">

          <div className="row border-0">
            <GroupsGeneral
            data={this.state.array}
            nameHandle={e => this.HandleChange(e, "name")}
            administHandle={e => this.HandleChange(e, "administration")}
            handleSelectChange={this.handleSelectChange.bind(this)}
            userAdministration = {this.props.userAdministration}/>
          </div>
        </div>

        <div className="tab-pane" id="Avancé" role="tabpanel" aria-labelledby="profile-tab">
          <div className="row  border-0">
            <GroupsAvance/>
          </div>
        </div>

        <div className="tab-pane" id="Utilisateurs" role="tabpanel" aria-labelledby="contact-tab">

          <div className="col-maxHeight  border-0">
            <GroupsUsers dataGroupName={this.props.match.params.groupName}
            tokenCSRF={this.props.tokenCSRF}/>
          </div>
        </div>

        <div className="tab-pane " id="Politiques" role="tabpanel" aria-labelledby="profile-tab">

          <div className="col border-0">
              <GroupsPolitiqueSecu data={this.state.arrayPolicies}
              handlePoliciesChange={this.handlePoliciesChange}
              defaultPolicies={this.state.defaultPolicies}
              handleDefaultPolicies={this.handleDefaultPolicies}
              extansionsAutorisation={this.state.extansionsAutorisation}
              handleChangeExtansionsAutorisation={this.handleChangeExtansionsAutorisation}
              handleChangeSelect={this.handleChangeSelect}
              selectedOptionExtensions={this.state.selectedOptionExtensions}
              handlePoliciesModif={this.handlePoliciesModif}
              handleLimitSize={this.handleLimitSize}
              limitSize={this.state.limitSize}
              handleChangeEmailfilter={this.handleChangeEmailfilter}
              tokenCSRF={this.props.tokenCSRF}/>
          </div>
        </div>


        <div className="tab-pane " id="Applications" role="tabpanel" aria-labelledby="profile-tab">

        { this.state.dataGroupReferenceApplications && this.state.dataAvailableReferenceApplications && this.state.dataGroupUnwantedApplications ?

          <div className="col border-0">
              <GroupsApplications dataGroupReferenceApplications={this.state.dataGroupReferenceApplications}
              dataAvailableReferenceApplications={this.state.dataAvailableReferenceApplications}
              dataGroupUnwantedApplications={this.state.dataGroupUnwantedApplications}
              dataGroupTestStoreReferenceApplications={this.state.dataGroupTestStoreReferenceApplications}
              dataGroupTestStoreUnwantedApplications={this.state.dataGroupTestStoreUnwantedApplications}
              dataGroupTestStoreAvailableReferenceApplications={this.state.dataGroupTestStoreAvailableReferenceApplications}
              handleGroupTestStoreApplicationVersionSelect={this.handleGroupTestStoreApplicationVersionSelect.bind(this)}
              useTestStore={this.state.useTestStore}
              handleUseTestStoreChange={this.handleUseTestStoreChange.bind(this)}
              selectedOption={this.state.selectedOption}
              handleChangeSelectApp={this.handleChangeSelectApp.bind(this)}
              selectedRefApp={this.selectedRefApp.bind(this)}
              selectedReferenceApplication={this.state.selectedReferenceApplication}
              addReferenceApplication={this.addReferenceApplication.bind(this)}
              removeReferenceApplication={this.removeReferenceApplication.bind(this)}
              addUnwantedApplication={this.addUnwantedApplication.bind(this)}
              removeUnwantedApplication={this.removeUnwantedApplication.bind(this)}
              addTestStoreReferenceApplication={this.addTestStoreReferenceApplication.bind(this)}
              removeTestStoreReferenceApplication={this.removeTestStoreReferenceApplication.bind(this)}
              addUnwantedTestStoreReferenceApplication={this.addUnwantedTestStoreReferenceApplication.bind(this)}
              removeUnwantedTestStoreReferenceApplication={this.removeUnwantedTestStoreReferenceApplication.bind(this)}/>
          </div>

          : this.state.errorDB_MM_APP_ApiGroupReferenceApplications ?

          <div className="col">
            <div className="card mt-4 mb-4 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)", "padding": "10px"}}>
              <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error DB MM-APP_ApiGroupReferenceApplications : {this.state.errorDB_MM_APP_ApiGroupReferenceApplications.message}</span>
            </div>
          </div>

          :
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
          }
        </div>

        <div className="tab-pane " id="Réseaux" role="tabpanel" aria-labelledby="profile-tab">

        { this.state.dataGroupWifinetwork ?

          <div className="col border-0">
              <GroupsWifinetwork dataGroupWifinetwork={this.state.dataGroupWifinetwork}
                addGroupWifinetwork={this.addGroupWifinetwork.bind(this)}
                removeGroupWifinetwork={this.removeGroupWifinetwork.bind(this)}
                userAdministration={this.props.userAdministration}/>
          </div>

          : this.state.errorDB_MM_APP_ApiGroupWifinetwork ?

          <div className="col">
            <div className="card mt-4 mb-4 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)", "padding": "10px"}}>
              <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error DB MM-APP_ApiGroupWifinetwork : {this.state.errorDB_MM_APP_ApiGroupWifinetwork.message}</span>
            </div>
          </div>

          :
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
          }
        </div>

        <div className="tab-pane " id="Simcards" role="tabpanel" aria-labelledby="profile-tab">

        { this.state.dataGroupSimcard && this.state.dataGroupSimcardAllApplications ?

          <div className="col border-0">
              <GroupsSimcards
              onStateChange={this.handleChildState}
              addNewSimcard={this.addNewSimcard.bind(this)}
              addReferenceApplicationSimcard={this.addReferenceApplicationSimcard.bind(this)}
              handleSimcardInfoSelect={this.handleSimcardInfoSelect.bind(this)}
              handleChangeSelectAppSimcard={this.handleChangeSelectAppSimcard.bind(this)}
              handleSimcardApplicationDisabled={this.handleSimcardApplicationDisabled.bind(this)}
              handleAurotiseRoamingChange={this.handleAurotiseRoamingChange.bind(this)}
              removeSimcardApplication={this.removeSimcardApplication.bind(this)}
              selectedSimcardInfo={this.state.selectedSimcardInfo}
              selectedOptionSimcardApp={this.state.selectedOptionSimcardApp}
              dataGroupSimcardAllApplications={this.state.dataGroupSimcardAllApplications}
              dataGroupSimcard={this.state.dataGroupSimcard}/>
          </div>

          : this.state.errorDB_MM_APP_ApiGroupSimcard ?

          <div className="col">
            <div className="card mt-4 mb-4 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)", "padding": "10px"}}>
              <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error DB MM-APP_ApiGroupSimcard : {this.state.errorDB_MM_APP_ApiGroupSimcard.message}</span>
            </div>
          </div>

          :
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
          }
        </div>


        <div className="tab-pane " id="Geofencing" role="tabpanel" aria-labelledby="profile-tab">

        { this.state.dataGroupGeofencingZone && this.state.dataGroupGeofencingAllApplications ?

          <div className="col border-0">
            <GroupsGeofencing
            activeTab={this.state.activeTab}

            newGeofencingZoneName={this.state.newGeofencingZoneName}
            newGeofencingZoneDescription={this.state.newGeofencingZoneDescription}
            newGeofencingZoneLatitude={this.state.newGeofencingZoneLatitude}
            newGeofencingZoneLatitudeDecimal={this.state.newGeofencingZoneLatitudeDecimal}
            newGeofencingZoneLongitude={this.state.newGeofencingZoneLongitude}
            newGeofencingZoneLongitudeDecimal={this.state.newGeofencingZoneLongitudeDecimal}
            newGeofencingZoneRadius={this.state.newGeofencingZoneRadius}
            newGeofencingZoneColor={this.state.newGeofencingZoneColor}
            dataGroupGeofencingZone={this.state.dataGroupGeofencingZone}

            showModalNewGeonfencingZone={this.showModalNewGeonfencingZone.bind(this)}
            addNewGeofencingZone={this.addNewGeofencingZone.bind(this)}
            addReferenceApplicationGeofencingZone={this.addReferenceApplicationGeofencingZone.bind(this)}


            selectedGeofencingZone={this.state.selectedGeofencingZone}
            selectedOptionGeofencingZoneApp={this.state.selectedOptionGeofencingZoneApp}
            handleGeofencingZoneSelect={this.handleGeofencingZoneSelect.bind(this)}
            handleNewGeofencingZoneChange={this.handleNewGeofencingZoneChange.bind(this)}
            handleNewGeofencingZoneColorChange={this.handleNewGeofencingZoneColorChange.bind(this)}
            removeGeofencingZoneApplication={this.removeGeofencingZoneApplication.bind(this)}
            removeGeofencingZone={this.removeGeofencingZone.bind(this)}
            handleChangeSelectAppGeofencingZone={this.handleChangeSelectAppGeofencingZone.bind(this)}
            handleGeofencingZoneApplicationDisabled={this.handleGeofencingZoneApplicationDisabled.bind(this)}
            dataGroupGeofencingAllApplications={this.state.dataGroupGeofencingAllApplications}/>
          </div>

          : this.state.errorDB_android ?

          <div className="col">
            <div className="card mt-4 mb-4 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)", "padding": "10px"}}>
              <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error db_android : {this.state.errorDB_android.message}</span>
            </div>
          </div>

          :
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
          }
        </div>

        <div className="tab-pane " id="Permissions" role="tabpanel" aria-labelledby="profile-tab">

        { this.state.dataGroupPermissions && this.state.dataAvailableGroupPermissions ?

          <div className="col border-0">
              <GroupsPermissions dataGroupPermissions={this.state.dataGroupPermissions}
              handleGroupPermissions={this.handleGroupPermissions}
              handlePermissionSelect={this.handlePermissionSelect.bind(this)}
              addGroupPermissions={this.addGroupPermissions.bind(this)}
              selectedPermission={this.state.selectedPermission}
              removeGroupPermissions={this.removeGroupPermissions.bind(this)}
              dataAvailableGroupPermissions={this.state.dataAvailableGroupPermissions}
              userAdministration={this.props.userAdministration}/>
          </div>

          : this.state.errorDB_MM_APP_ApiGroupPermissions ?

          <div className="col">
            <div className="card mt-4 mb-4 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)", "padding": "10px"}}>
              <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error DB MM-APP_ApiGroupPermissions : {this.state.errorDB_MM_APP_ApiGroupPermissions.message}</span>
            </div>
          </div>

          :
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
          }
        </div>



        <div className="tab-pane " id="WallpaperAndLockscreen" role="tabpanel" aria-labelledby="profile-tab">

        { this.state.dataGroupWallpaperAndLockscreen ?

          <div className="col border-0">
              <GroupsWallpaper dataGroupWallpaperAndLockscreen={this.state.dataGroupWallpaperAndLockscreen}
              handleGroupWallpaperAndLockscreen={this.handleGroupWallpaperAndLockscreen.bind(this)}
              deleteGroupWallpaperOrLockscreen={this.deleteGroupWallpaperOrLockscreen.bind(this)}/>
          </div>

          : this.state.errorDB_MM_APP_ApiGroupWallpaperAndLockscreen ?

          <div className="col">
            <div className="card mt-4 mb-4 border-warning rounded-0" style={{backgroundColor: "rgb(255,230,143)", "padding": "10px"}}>
              <span style={{ "padding": "5px", "paddingLeft": "50px", "paddingRight": "50px"}}>Error DB MM-APP_ApiGroupWallpaperAndLockscreen : {this.state.errorDB_MM_APP_ApiGroupWallpaperAndLockscreen.message}</span>
            </div>
          </div>

          :
              <div className="card-body d-flex justify-content-center">

                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
                </div>
              </div>
          }
        </div>


      </div>

      <div className="row fixed-bottom d-flex justify-content-center  mt-3 mb-3">
        <div className="text-center mr-1">
          <button type="button" className="btn btn-secondary" onClick={GroupPage.backPage}><FormattedMessage id="retour"/>
          </button>
        </div>

        {this.props.userAdministration === "ADMINISTRATOR" || this.props.userAdministration === "REMOTE_ADMIN" ?

        <div className="text-center ml-1">
          <button type="button" className={`btn btn-${this.state.validButton}`} onClick={this.sendModif.bind(this)} style={{width: "5vw"}}>
          {this.state.sendingModif ?
            <div className="spinner-border text-light spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            : <FormattedMessage id="valider"/>
          }
          </button>
        </div>
        :
        <div className="text-center ml-1">
          <button type="button" className={`btn btn-${"info"}`} disabled title={this.props.intl.formatMessage({id: "supervisor.restriction.msg"})}>
            <FormattedMessage id="valider"/>
          </button>
        </div>
        }


      </div>

      <div className="modal fade" id="ModalValideModif" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{
            width: '160px'
          }} role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-center">
              <h6 className="modal-title text-info" id="exampleModalLabel">
                <b>
                  {this.props.intl.formatMessage({id: "chargement"})} ...
                </b>
              </h6>
            </div>
            <div className="modal-body text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{this.props.intl.formatMessage({id: "chargement"})} ...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ConfirmationSupression" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modalSecurite rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "supprimer"})} : "<b className="text-info">{this.state.groupName}</b>"
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.deleteGroup.bind(this)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ConfirmationTestStore" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modalSecurite rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "supprimer"})} : "<b className="text-info">{this.state.groupName}</b>"
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ml-2">
              <h6 className="modal-title" id="modalAjoutDNSLabel">{this.props.intl.formatMessage({id: "confirmer"})}</h6>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
              </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.setGroupTestStore.bind(this)}><FormattedMessage id="supprimer"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ModalErreur" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modalAlert">
            <div className="modal-header modalAlert">
              <h5 className="alert-heading">
                <b>Code error :
                </b>{this.state.codeStatus}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <p>{this.state.errorM}</p>
              <hr/>
              <p className="mb-0">{this.state.errorMsg}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modalCheckbox" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{this.state.modalCheckboxTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">*
              {this.state.modalCheckboxMsg}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.intl.formatMessage({id: "annuler"})}</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal"
                onClick={this.handleCheckboxConfirm.bind(this, this.state.modalCheckboxField)}>{this.state.modalCheckboxButton}</button>
            </div>
          </div>
        </div>
      </div>

    </div>)
  }
}
GroupPage.propTypes = {
  intl: intlShape.isRequired
};
GroupPage = injectIntl(GroupPage, {withRef: true});

export default GroupPage
