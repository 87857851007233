import React from 'react'
import find from 'lodash/find';
import Select, { components } from 'react-select';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';

import TablePlus from '../Components/TablePlus';

class GroupsWifinetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  modalConfirm(name, index) {
    this.setState({modalTXT: name, modalIndex: index})
  }

// ------------------------------- Component MOUNT / UNMOUNT ------------------- //

AdaptWifinetworkLabel(groupWifinetwork){
  var wifiList = []
  for (var i = 0; i < groupWifinetwork.length; i++) {
    wifiList.push(groupWifinetwork[i].wifinetwork);
  }
  return wifiList
}

// ------------------------------- Action handle ------------------------------- //

// ------------------------------- Render -------------------------------------- //

  render() {
    return (
      <div>

        <h5 className="text-primary text-center mt-2">
          <b><FormattedMessage id="groupsDetails.wifinetwork.title"/></b>
        </h5>
        <hr/>

            <div className="col-8 mt-4 container">

                  <TablePlus
                  title={this.props.intl.formatMessage({id: "groupsDetails.wifinetwork.subTitle"})}
                  data={this.AdaptWifinetworkLabel(this.props.dataGroupWifinetwork)}
                  modalAddId="modalAddWifinetwork"
                  deleteHandler={this.modalConfirm.bind(this)}
                  modalRemoveId="modalConfirmSuprWifinetwork"
                  userAdministration={this.props.userAdministration}
                  maxHeight="235px"
                  />

            </div>

            <div className="modal fade" id="modalAddWifinetwork" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalAddWifinetworkLabel"><FormattedMessage id="groupsDetails.wifinetwork.addMenu"/></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" id="WifinetworkNameInput" placeholder={this.props.intl.formatMessage({id: "groupsDetails.wifinetwork.addWifinetwork.wifiNetworkName"})} aria-label="WifinetworkName" aria-describedby="basic-addon1"/>
                    </div>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" id="WifinetworkPasswordInput" placeholder={this.props.intl.formatMessage({id: "groupsDetails.wifinetwork.addWifinetwork.wifiNetworkPassword"})} aria-label="WifinetworkPassword" aria-describedby="basic-addon1"/>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="fermer"/>
                    </button>
                    <button type="button" className="btn btn-primary" onClick={this.props.addGroupWifinetwork.bind(this)}><FormattedMessage id="ajouter"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modalConfirmSuprWifinetwork" tabIndex="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered " role="document">
                <div className={`modal-content modalConfirm border-secondary`}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="modalAjoutDNSLabel">
                      {this.props.intl.formatMessage({id: "supprimer"})}
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body ml-2">
                    <h6 className="modal-title" id="modalAjoutDNSLabel">
                      {this.props.intl.formatMessage({id: "confirmDelete"})} "<b className="text-info">{this.state.modalTXT}</b>" ?
                    </h6>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal"><FormattedMessage id="annuler"/>
                    </button>
                    <button type="button" className={`btn btn-danger`} data-dismiss="modal" onClick={this.props.removeGroupWifinetwork.bind(this, this.state.modalIndex)}><FormattedMessage id="valider"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

    </div>
    )
  }
}
GroupsWifinetwork.propTypes = {
  intl: intlShape.isRequired
};
GroupsWifinetwork = injectIntl(GroupsWifinetwork,
                        {withRef: true});

export default GroupsWifinetwork
